/* globals POGO_EA_HOST, POGO_EA_ACCOUNT_HOST */
import urls from 'config/urls';
import { routes } from 'config/routes';
import {
	AD_QUERY_PARAMS, getCouponCodeFromLocalStorage, getLocalStorage, isLive, setPromoSelectedInLocalStorage
} from 'util/index';
import { store, USER, GA4_CLIENT_ID, GA4_SESSION_ID } from 'util/store';
import { route as navigate } from 'preact-router';
import { setPerformanceMark } from 'services/performance';
import { logError } from 'services/logger';
import { dispatchEvent } from '@pogo-internal/events/eventManager';
import { CLICK, SELECT_PROMOTION } from '@pogo-internal/events/events';
import { CLICK_LOCATION, DESTINATION_URL, PROMOTION_ID } from '@pogo-internal/events/attributes';

// add game brick routes
Object.keys(routes).forEach(url => {
	let route = routes[url];
	if (route.component === 'pregame') {
		// dynamically generated route for game brick
		routes[`${url}/play`] = {
			...route,
			component: 'game',
			spotlight: false,
			systemMessages: false,
			header: false,
			footer: false,
			seo: false
		};
	}
});

// merge routes into urls map
Object.keys(routes).forEach(url => {
	let route = routes[url];
	switch (route.component) {
		case 'pregame':
			urls[`pregame/${route.gameCode}`] = url;
			break;
		case 'game':
			urls[`game/${route.gameCode}`] = url;
			break;
		case 'category':
			urls[`category/${route.categoryId}`] = url;
			break;
		case 'codeRedemptionFlow':
			urls[`redeem/${route.type}`] = url;
			break;
		case 'redirect':
			break;
		default:
			urls[route.component] = url;
	}
});

export function getRoutes() {
	return routes;
}

for (let key in urls) {
	if (Object.prototype.hasOwnProperty.call(urls, key)) {
		urls[key] = urls[key].replace('{host.pogoEa}', POGO_EA_HOST).replace('{host.pogoEAAccount}', POGO_EA_ACCOUNT_HOST);
	}
}

let sendScrollTelemetry;
export function setScrollTelemetryFunction(func) {
	sendScrollTelemetry = func;
}

export function sendScrollEvent() {
	sendScrollTelemetry && sendScrollTelemetry();
}

/**
 * load a url using key defined in urls config / lines 10-45 above
 * @param {string} key url key
 * @param {string} pageLocation location where url is loaded from
 * @param {Object} queryParamsObject
 * @param {boolean} track indicates if load should be tracked via GA heatmap, should be true only on user click initiated loads
 * @param {string} intcmp GA intcmp, only sent if track is true
 * @param {string} target if _blank then url opens in new window
 */
export function load(key, pageLocation, queryParamsObject, track = true, intcmp = '', target = '', safe) {
	let url = getURL(key);
	url = addQueryParams(url, queryParamsObject);
	loadUrl(url, pageLocation, intcmp, track, target, safe);
}

export function getURL(key) {
	return urls[key];
}

export function addQueryParams(url, queryParamsObject) {
	let urlKey = '', hash = '';
	// keys for which we should add extra query params like site, redirect and GA clientId
	const keys = ['silentAuthenticate', 'signIn', 'signOut', 'register', 'subscribe', 'gemFunding'];
	keys.forEach(key => {
		if (url.includes(urls[key])) {
			urlKey = key;
		}
	});
	if (urlKey) {
		let {
			[USER]: user = {},
			[GA4_CLIENT_ID]: ga4ClientId,
			[GA4_SESSION_ID]: ga4SessionId
		} = store.getState();

		queryParamsObject = {
			ga4ClientId, ga4SessionId,
			unid: user.unid,
			site: user.site,
			...getRedirectParam(urlKey),
			...queryParamsObject
		};
	}
	if (urlKey === 'subscribe') {
		queryParamsObject = {
			...queryParamsObject,
			...(getLocalStorage(AD_QUERY_PARAMS) || {}),
			couponCode: (getCouponCodeFromLocalStorage() || {}).couponCode
		};
	}
	let queryParams = queryParamsObject ? generateQueryParams(queryParamsObject) : '';
	if (url.includes('?')) {
		queryParams = queryParams.replace('?', '&');
	}
	return url + queryParams + hash;
}

export function generateQueryParams(queryParamsObject) {
	let params = Object.keys(queryParamsObject).filter(key => queryParamsObject[key] &&
		encodeURIComponent(queryParamsObject[key]))
		.map(key => key + '=' + encodeURIComponent(queryParamsObject[key])).join('&');
	return params ? `?${params}` : '';
}

export function loadUrl(url, pageLocation, intcmp, track = true, target, safe) {
	sendScrollEvent();
	if (url.indexOf(location.origin) === 0) {
		url = url.replace(location.origin, '') || '/';
	}
	let newWindow = target === '_blank';
	let hardNav = isExternal(url) || isLive('noSpa');
	if (intcmp) {
		setPromoSelectedInLocalStorage(intcmp);
		dispatchEvent(SELECT_PROMOTION, { [PROMOTION_ID]: intcmp });
	}

	if (track) {
		dispatchEvent(CLICK, { [CLICK_LOCATION]: pageLocation, [DESTINATION_URL]: url } );
	}

	if (newWindow) {
		window.open(url, 'window-' + (+new Date()), !isTrustedUrl(url) || !safe ? 'noopener' : '');
	}
	else if (hardNav) { // wait 1ms to allow GA to track click event
		window.setTimeout(() => window.location.href = url, 1);
	}
	else {
		navigate(url);
	}

	// get path from url and use that
	const path = window.location.pathname;
	if (path.includes('/games/') && path.includes('/play')) {
		setPerformanceMark(`Play Game`);
	}
}

export function isExternal(url, target) {
	if (url.indexOf(location.origin) === 0) {
		url = url.replace(location.origin, '') || '/';
	}
	return !url.match(/^\//g) || url.indexOf('/server/') === 0 || target === '_blank' ||
		(isLive('heavyAdInterventionFix') && url.match(/\/play$/g));
}

function isTrustedUrl(url) {
	return new URL(document.baseURI).origin === new URL(url, document.baseURI).origin;
}

function getRedirectParam(key) {
	let redirectUrl = location.href;
	if (key === 'signOut') {
		redirectUrl = location.origin + getURL('signout');
	}
	// redirect to home page instead of sign out page if user signs in from signout page or create persona
	if (location.pathname === getURL('signout') || location.pathname === getURL('createPersona')) {
		redirectUrl = location.origin + getURL('home');
	}
	return { redirect: redirectUrl };
}

/**
 * returns an internal url if the queryParam value is validated as an internal url,
 * or the current location origin if the queryParam value is invalidated
 * @param {string} queryParam query parameter key
 */
export function getInternalUrlFromParam(queryParam) {
	let searchParams = new URLSearchParams(location.search);
	let redirectUrl = searchParams.get(queryParam);
	if (!redirectUrl || !isPogoInternalUrl(redirectUrl)) {
		redirectUrl = window.location.origin;
	}
	return redirectUrl;
}

/**
 * validate url is an internal url
 * @param {string} url uniform resource locator in string format
 */
export function isPogoInternalUrl(url) {
	let hostname = '';
	try {
		hostname = new URL('', url).hostname;
	}
	catch (e) {
		logError(e, 'urlService - isPogoInternalUrl');
	}
	return hostname.endsWith('.pogo.com') || hostname.endsWith('.pogobeta.com');
}

export function getUrlOrigin(url) {
	let origin = '';
	try {
		origin = new URL('', url).origin;
	}
	catch (e) {
		logError(e, 'urlService - getUrlOrigin');
	}
	return origin;
}
